.search-input {
  border-right: 0;
  background-color: var(--card-bg-color);
}

.search-button {
  border-left: 0;
  border-color: var(--card-border-color);
  background-color: var(--card-bg-color);
  color: var(--primary-color);
}

  .search-button:hover {
    color: #0056b3;
    border-color: var(--card-border-color);
    background-color: var(--card-bg-color);
  }

.search-clear {
  font-size: 11px;
  border-radius: 0 !important;
  border-bottom: 1px dashed;
  padding: 0 0.1em;
  margin: 0.5em;
}