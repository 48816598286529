.header-jumbotron {
  background-image: url('../_img/jumbotron-bg.png');
  background-size: cover;
  background-position: calc(20% - 40px), 0;
  height: 310px;
}

@media(min-width: 2450px) {
  .header-jumbotron {
    background-position: left top; 
  }
}


.header-breadcrumbs {
  background-color: var(--link-active-color);
  font-size: 12px;
  color: white;
}

.header-breadcrumbs a {
  color: white;
}