.video-container {
  position: relative;
  padding: 0;
  width: auto;
  height: auto;
}

.video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-preview:hover {
  z-index: -1000;
}