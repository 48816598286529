.pagination {
  cursor: pointer;
}

.pagination .disabled {
  cursor: auto;
}

.pagination .active {
  cursor: auto;
}
