body {
  background-color: var(--app-bg-color);
  color: var(--text-color);
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  flex: 1;
}

.container {
  padding: 0;
  flex: 1 1;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1198px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 1rem;
  text-transform: uppercase;
}

/* ��������� �������� */
h1 {
  font-size: 22px;
  color: var(--link-active-color);
}

/* �������� ���������*/
h2 {
  font-size: 16px;
  font-weight: bold;
}

/* �������� �����*/
h3 {
  margin-top: 0.1rem;
  font-size: 16px;
  text-transform: none;
  font-weight: bold;
}

/* ��������� �� �������� */
h4 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid var(--hr-color);
}

/* ��������� �� ������� */
h5 {
  font-size: 14px;
  font-weight: bold;
}

a,
.link {
  color: var(--link-active-color);
}

.btn-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-important {
  color: var(--important-color);
}

label,
.text-label {
  color: var(--label-color);
  font-size: 13px;
}

.account-nav a.active {
  padding-bottom: 0.1em;
  border-bottom: 0.4em solid #0366d640;
}

.form-check-label {
  color: var(--text-color);
}
