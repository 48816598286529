.navbar-brand a {
  white-space: normal;
  word-break: break-all;
  font-size: 10px;
  text-transform: uppercase;
}

.navbar-brand img {
  height: 90px;
  margin: 4px 0;
}

.navbar-main {
  background-image: url("../_img/navbar_bg.png");
  background-size: cover;
  height: 117px;
}

.navbar-main .navbar-nav {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.navbar-main .navbar-nav a.nav-link {
  color: var(--text-color);
  font-weight: bold;
}

.navbar-main .navbar-nav a.nav-link:hover {
  color: var(--link-active-color);
}

.navbar-main .navbar-nav .nav-item {
  margin-left: 0;
  margin-right: 20px;
}
