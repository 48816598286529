.layout {
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex: 1;
  margin-bottom: 0.2rem;
  background-image: url('../_img/down_bg.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
}
