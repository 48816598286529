.programpage-course {
  border-left: 5px solid var(--card-border-color);
  padding-left: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: stretch;
}

.programpage-course-main {
  flex: 1;
}

.programpage-course-aside {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
}

.programpage-course-img {
  width: 315px;
  height: 140px;
  object-fit: cover;
}