.download-wrapper {
  position: relative;
}

.download-previewimg {
  width: var(--mediacontent-width);
  height: var(--mediacontent-height);
}

.download-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
  padding: 0;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  text-align: center;
  color: white
}

.download-btn a {
  color: white;
  filter: drop-shadow(0px 0px 3px black);
}

.download-btn a:hover {
  filter: drop-shadow(0px 0px 5px #e3e3e3);
}
