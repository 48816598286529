.files {
  min-height: 6rem;
  position: relative;
  display: flex;
  align-items: stretch;
}

  .files input {
    height: 100%;
    outline: 2px dashed #92b0b3;
    outline-offset: -4px;
    margin: 0;
  }

  .files svg {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin: -0.75rem 0 0 -0.75rem;
    display: block;
  }

.files-error {
  position: absolute;
  left: 5%;
  width: 90%;
  bottom: 2%;
}

.files-preview {
  word-wrap: break-word;
}

  .files-preview img {
    max-height: 5rem;
    object-fit: cover;
  }
