.catalogcard {
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: stretch;
  align-items: stretch;
}

.catalogcard-aside {
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.catalogcard-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.catalogcard-main {
  flex: 1;
  margin: 1rem 0.5rem;
}


.catalogcard-buy {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 1px solid var(--card-border-color);
  background-color: var(--footer-bg-color);
  margin: 0;
}
.catalogcard-price {
  color: var(--important-color);
  font-size: 13px;
  margin: 1rem 0;
}
.catalogcard-price-value {
  font-weight: bold;
  font-size: 16px;
}

.programcard {
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: stretch;
  align-items: stretch;
}

.programcard-header {
  padding: 0 1.5rem;
}

.programcard-footer {
  padding: 0 1.5rem;
  background-color: var(--footer-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.injected .catalogcard-buy {
  background: none;
  margin: 1.5rem 0;
}

.injected.catalogcard {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}