.sortoption {
  color: var(--form-control-text-color);
  font-size: 14px;
  padding-bottom: 0.1rem;
  border-radius: 0 !important;
  border-bottom: 1px dashed;
  padding-left: 0.1em;
  padding-right: 0.1em;
  margin: 0 0.5rem;
}
