:root {
  --app-bg-color: #F9F9F9;
  --footer-bg-color: #ECECEC;
  --text-color: #707070;
  --text-header-color: #2884D7;
  --card-bg-color: #FFFFFF;
  --card-border-color: #C7C7C7;
  --link-active-color: #2884D7;
  --link-inactive-color: #3E3E3E;
  --form-control-text-color: #3E3E3E;
  --label-color: #A8A8A8;
  --hr-color: #D4D4D4;
  --primary-color: #2884D7;
  --important-color: #CF3535;
  --danger-color: #CF3535;
}
